.special-label
{
    display: none;
}


.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(213, 60, 178, 1);
    outline: 0;
    box-shadow: 0rem 0rem 0rem 0.125rem rgba(213, 60, 178, 1);
}

.react-tel-input{
    .form-control {
        border-radius: 0.5rem;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        border-left-width: 1px;
    }
}

.otp-container{
    

    gap: 0.5rem; 
    justify-content: space-between; 
    width: 100% !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    height: auto !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    color: #495057 !important;
    background-color: #ffffff !important;
    pointer-events: auto;

    input{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; 
    flex: 1 1 0%; 
    outline-style: none; 
    border: 0.01px solid #495057;
    border-radius: 0.8rem;
    width: inherit !important;
    margin-right: auto !important;

    &:focus{
    
        box-shadow: 0rem 0rem 0rem 0.125rem rgba(213, 60, 178, 1);
    }

    }

}




.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}



